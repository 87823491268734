import React from 'react';
import '../assets/css/SideBar.css';
import { Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { showDefaultAlert } from '../utils/alerts';
import { useNavigate } from 'react-router-dom';

export default function SideBar() {
  const navigate = useNavigate();

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
    }
  }

  return (
    <div className='side'>
      <li className='row' onClick={() => navigate('/')}>Home</li>
      <li className='row' onClick={() => navigate('/user')}>User</li>
      <li className='row' onClick={() => navigate('/vehicles')}>Vehicles</li>
      <li className='row' onClick={() => navigate('/listings')}>Listings</li>
      <li className='row' onClick={() => navigate('/outage')}>API Outage</li>
      <li className='row' onClick={() => navigate('/promotion')}>Promotions</li>
      <li className='row' onClick={() => navigate('/dealer')}>Dealer</li>
      <li className='row' onClick={() => navigate('/featuredDealer')}>Featured Dealer</li>
      <li className='row' onClick={() => navigate('/featuredVehicles')}>Featured Vehicles</li>
      <div className='signout'>
        <Button onClick={() => signOut()}>SignOut</Button>
      </div>
    </div>
  );
}
