import React, { useState, useEffect, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'react-bootstrap';
import { TimestampToDate } from '../../utils/format';
import { updateClaims } from '../../services/ClaimService';
import VehicleModel from '../VehicleModel';
import InvokeAPIModel from './InvokeAPIModel';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';

export const ClaimsTable = (props) => {

  const { IndividualClaimRecords, DealerClaimRecords } = props.searchResults.data;

  const { token } = useContext(UserContext);


  const [activeindividualClaims, setActiveindividualClaims] = useState([]);
  const [inactiveindividualClaims, setInactiveindividualClaims] = useState([]);
  const [expiredindividualClaims, setExpiredindividualClaims] = useState([]);

  const [activeDealerClaims, setActiveDealerClaims] = useState([]);
  const [inactiveDealerClaims, setInactiveDealerClaims] = useState([]);
  const [expiredDealerClaims, setExpiredDealerClaims] = useState([]);

  const [claim, setClaim] = useState([]);
  const [showVehicleModel, setShowVehicleModel] = useState(false);
  const [showInvokeAPIModel, setShowInvokeAPIModel] = useState(false);
  const [vehiClaimID, setVehiClaimID] = useState();

  const handleVehicleModel = (claimID) => {
    setVehiClaimID(claimID);
    setShowVehicleModel(true);
  };

  const handleInvokeAPIModel = (claim) => {
    setClaim(claim);
    setShowInvokeAPIModel(true);
  };

  useEffect(() => {
    if (IndividualClaimRecords) {
      let active = [];
      let inactive = [];
      let expired = [];

      for (let claim of IndividualClaimRecords) {
        if (claim.Status === 'Active') {
          active.push(claim);
        } else if (claim.Status === 'Inactive') {
          inactive.push(claim);
        } else if (claim.Status === 'Expired') {
          expired.push(claim);
        }
      }

      setActiveindividualClaims(active);
      setInactiveindividualClaims(inactive);
      setExpiredindividualClaims(expired);
    }

    if (DealerClaimRecords) {
      let active = [];
      let inactive = [];
      let expired = [];

      for (let claim of DealerClaimRecords) {
        if (claim.Status === 'Active') {
          active.push(claim);
        } else if (claim.Status === 'Inactive') {
          inactive.push(claim);
        } else if (claim.Status === 'Expired') {
          expired.push(claim);
        }
      }

      setActiveDealerClaims(active);
      setInactiveDealerClaims(inactive);
      setExpiredDealerClaims(expired);
    }
  }, [DealerClaimRecords, IndividualClaimRecords]);

  const handleExpireClaim = async (claimID) => {

    const confirmationMessage = 'Are you sure you want to Expire Claim?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await updateClaims(claimID, token);
          showDefaultAlert('success', 'Success', 'Successfully updated the claim expiration time.');
          return response;

        } catch (error) {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      },
      onCancel: () => { },
    });
  };

  return (
    <div>

      <div>
        <h3 className="claim-topic-color">Claims!</h3>
        <div>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            {activeindividualClaims && activeindividualClaims.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Active personal Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                          <th>Invoke API</th>
                          <th>Details</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activeindividualClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleInvokeAPIModel(claim)}
                              >
                                Invoke API
                              </Button>
                            </td>
                            <td>
                              <Button className="btn btn-danger" onClick={() => handleVehicleModel(claim.ClaimID)}>Details</Button>
                            </td>
                            <td style={{ width: '15%' }}>
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleExpireClaim(claim.ClaimID)}
                              >
                                Expire Claim
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {expiredindividualClaims && expiredindividualClaims.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Expired Personal Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expiredindividualClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {inactiveindividualClaims && inactiveindividualClaims.length > 0 && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>Inactive Personal Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inactiveindividualClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
        <br />
        <div>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            {activeDealerClaims && activeDealerClaims.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Active Dealer Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                          <th>Invoke API</th>
                          <th>Details</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activeDealerClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                            <td style={{ width: '15%' }}>
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleInvokeAPIModel(claim)}
                              >
                                Invoke API
                              </Button>
                            </td>
                            <td>
                              <Button className="btn btn-danger" onClick={() => handleVehicleModel(claim.ClaimID)}>Details</Button>
                            </td>
                            <td style={{ width: '15%' }}>
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleExpireClaim(claim.ClaimID)}
                              >
                                Expire Claim
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {expiredDealerClaims && expiredDealerClaims.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Expired Dealer Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expiredDealerClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {inactiveDealerClaims && inactiveDealerClaims.length > 0 && (
              <Accordion.Item eventKey="5">
                <Accordion.Header>Inactive Dealer Claims</Accordion.Header>
                <Accordion.Body>
                  <div className="table-container">
                    <table className="table align-middle mb-0 mt-2 bg-white">
                      <thead className="bg-light">
                        <tr>
                          <th>Plate Number/VIN</th>
                          <th>Claimed Date</th>
                          <th>Expire Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inactiveDealerClaims.map((claim, index) => (
                          <tr key={index}>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                            </td>
                            <td style={{ width: '20%' }}>
                              <p className="text-muted mb-0">{claim.Type}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
        <br />
        {showInvokeAPIModel && <InvokeAPIModel closeModel={setShowInvokeAPIModel} claim={claim} />}
        {showVehicleModel && <VehicleModel closeModel={setShowVehicleModel} vehiClaimID={vehiClaimID} />}
      </div>
    </div>
  );
};
