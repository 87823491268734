import './index.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from '../src/components/Layout';
import Home from './pages/Home';
import User from './pages/User';
import SingleSignOn from './pages/SingleSignOn';
import Vehicles from './pages/Vehicles';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './aws-config';
import { UserProvider } from './contexts/UserContext';
import APIOutage from './pages/APIOutage';
import Listings from './pages/Listings';
import Promotion from './pages/Promotion';
import Dealer from './pages/Dealer';
import { FeaturedDealer } from './pages/FeaturedDealer';
import FeaturedVehicles from './pages/FeaturedVehicles';

// Configure Amplify
Amplify.configure(awsConfig);

function App() {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        // Fetch the current authenticated user's attributes
        const user = await Auth.currentAuthenticatedUser();
        // Get the email attribute from the user object
        setEmail(user.signInUserSession.idToken.payload.email);
        setToken(user.signInUserSession.idToken.jwtToken);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
    getUserAttributes();
  }, []);

  return (
    <Router>
      <UserProvider value={{ token, setToken, email, setEmail }}>
        <Routes>
          <Route path="/signin" element={<SingleSignOn />} />
          <Route element={<Layout />}>
            <Route path="/" element={token ? <Home /> : <Navigate to="/signin" />} />
            <Route path="/user" element={token ? <User /> : <Navigate to="/signin" />} />
            <Route path="/vehicles" element={token ? <Vehicles /> : <Navigate to="/signin" />} />
            <Route path="/listings" element={token ? <Listings /> : <Navigate to="/signin" />} />
            <Route path="/outage" element={token ? <APIOutage /> : <Navigate to="/signin" />} />
            <Route path="/promotion" element={token ? <Promotion /> : <Navigate to="/signin" />} />
            <Route path="/dealer" element={token ? <Dealer/> : <Navigate to="/signin" />}/>
            <Route path="/featuredDealer" element={token ? <FeaturedDealer /> : <Navigate to="/signin" />} />
            <Route path="/featuredVehicles" element={token ? <FeaturedVehicles /> : <Navigate to="/" />} />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
