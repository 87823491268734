import React, { useContext, useState } from 'react';
import { getLimitations, updateLimitations } from '../../services/LimitationService';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import SpinnerA from "../Spinner";


const LimitationsTable = ({searchTerm}) => {

  const email = searchTerm;

  const { token } = useContext(UserContext);


  const [selectedOption, setSelectedOption] = useState('');
  const [hourlyUsage, setHourlyUsage] = useState('');
  const [dailyUsage, setDailyUsage] = useState('');
  const [monthlyUsage, setMonthlyUsage] = useState('');
  const [dailyStart, setDailyStart] = useState('');
  const [hourStart, setHourStart] = useState('');
  const [userID, setUserID] = useState('');
  const [monthlyStart, setMonthlyStart] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLimitation, setShowLimitation] = useState(false);

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleLimitationSearch = async () => {
    if (!selectedOption) {
      showDefaultAlert('warning', 'Error', 'Please select an API.');
      return;
    }
    setLoading(true);
    try {
      const response = await getLimitations(email, selectedOption, token);
      setHourlyUsage(response.data.HourlyUsage);
      setDailyUsage(response.data.DailyUsage);
      setMonthlyUsage(response.data.MonthlyUsage);
      setHourStart(response.data.HourStart);
      setDailyStart(response.data.DailyStart);
      setMonthlyStart(response.data.MonthlyStart);
      setUserID(response.data.UserID);
      setLoading(false)
      setShowLimitation(true);

    } catch (error) {
      setShowLimitation(false);
      if (error.response && error.response.status === 400) {
        if (error.response.data === "Limit record not exists") {
          showDefaultAlert('error', 'Not Exists', "Limit record not exists");
        }
        else showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        setLoading(false)
      }
    }
  };

  const handleLimitationReset = async () => {
    const confirmationMessage = 'Are you sure you want to reset API Llimits?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await updateLimitations(userID, selectedOption, dailyStart, dailyUsage, hourStart, hourlyUsage, monthlyStart, monthlyUsage, token);
          showDefaultAlert('success', 'Success', 'The selected API limit has been successfully reset.');
          return response;

        } catch (error) {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      },
    });
  };

  const handleHourlyLimit = () => {
    const currentDate = new Date();
    const timestamp = Math.floor(currentDate.getTime() / 1000).toString();
    setHourStart(timestamp);
    setHourlyUsage(0);
  };

  const handleDailyLimit = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    setDailyUsage(0);
    setDailyStart(currentDate);
  };
  const handleMonthlyLimit = () => {
    const currentDate = new Date().toISOString().slice(0, 7);
    setMonthlyUsage(0);
    setMonthlyStart(currentDate);
  };

  return (
    <div>
      <div className="row mb-5">
        <div className="col-2">
          <h6 style={{ padding: '12px', float: 'right' }}>API</h6>
        </div>
        <div className="col-6 mb-2" style={{ marginTop: '5px' }}>
          <select
            className="form-control"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="" disabled>Select API</option>
            <option value="ConfirmCurrentRegisteredPerson">Confirm Current Registered Person API</option>
            <option value="InquireVehicleDetails">Inquire Vehicle Details API</option>
            <option value="InquireMoneyOwingVehicle">Inquire Money Owing Vehicle API</option>
            <option value="InquireStolenVehicle">Inquire Stolen Vehicle API</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="col-3">
          <button
            className="btn btn-danger"
            id="api-search-button"
            type="button"
            style={{ height: '90%' }}
            onClick={handleLimitationSearch}
          >
            Get usage count
          </button>
        </div>
      </div>
      <div>
        {loading ? (<SpinnerA />) : (
          <div>
            {showLimitation && (
            <div>
              <div className="row mb-5">
                <div className="col-2">
                  <h6 style={{ padding: '12px', float: 'left' }}>Hourly Usage</h6>
                </div>
                <div className="col-3 mb-2" style={{ marginTop: '5px' }}>
                  <input
                    className="form-control"
                    value={hourlyUsage}
                    placeholder='--/--'
                    disabled

                  >
                  </input>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    id="hourly-reset-button"
                    type="button"
                    onClick={handleHourlyLimit}
                    style={{ height: '90%' }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2">
                  <h6 style={{ padding: '12px', float: 'left' }}>Daily Usage</h6>
                </div>
                <div className="col-3 mb-2" style={{ marginTop: '5px' }}>
                  <input
                    className="form-control"
                    value={dailyUsage}
                    placeholder='--/--'
                    disabled
                  >
                  </input>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    id="daily-reset-button"
                    type="button"
                    onClick={handleDailyLimit}
                    style={{ height: '90%' }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2">
                  <h6 style={{ padding: '12px', float: 'left' }}>Monthly Usage</h6>
                </div>
                <div className="col-3 mb-2" style={{ marginTop: '5px' }}>
                  <input
                    className="form-control"
                    value={monthlyUsage}
                    placeholder='--/--'
                    disabled
                  >
                  </input>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    id="monthly-search-button"
                    type="button"
                    onClick={handleMonthlyLimit}
                    style={{ height: '90%' }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    id="reset-button"
                    type="button"
                    style={{ height: '90%', }}
                    onClick={handleLimitationReset}
                  >
                    Confirm Reset
                  </button>
                </div>
              </div>
            </div>
            )}
          </div>
        )}

      </div>
    </div>
  );
};

export default LimitationsTable;